import PropTypes from 'prop-types'
import kebabCase from 'lodash/kebabCase'
import { TableOfContents } from './components'
import * as styles from './LegalModule.module.scss'
import { Heading } from '../../typography/Heading'
import { Container, Row, Col } from '../../grid'
import { RichText } from '../../rich-text'
import { TableModule } from '../Table/TableModule'

const LegalModule = ({ pageTitle, sections }) => {
  const contentsTable = sections
    .filter(section => typeof section.sectionTitle === 'string' && section.sectionTitle.length > 0)
    .map(section => {
      return {
        name: section.sectionTitle,
        /* Eliminate all whitespace from the title string and use that as the anchor ID */
        to: `#${kebabCase(section.sectionTitle)}`,
      }
    })

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Heading className={styles.LegalHeading} element="h1" size={2}>
            {pageTitle}
          </Heading>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <TableOfContents pageTitle={pageTitle} anchorLinks={contentsTable} />
        </Col>
        <Col xs={12} md={9}>
          {sections.map(section => {
            /* Eliminate all whitespace from the title string and use that as the anchor ID */
            const sectionId = kebabCase(section.sectionTitle)

            if (section.moduleType === 'table') {
              const tableId = kebabCase(section.title)
              return (
                <div
                  key={`key-${tableId}`}
                  className={(styles.Section, styles.TableSection)}
                  id={tableId}
                  data-qa="legal-module-section"
                >
                  <TableModule {...section} embedded />
                </div>
              )
            }

            return (
              <div
                key={`key-${sectionId}`}
                className={styles.Section}
                data-qa="legal-module-section"
              >
                <Heading element="h2" size={3} id={sectionId} tabIndex="0">
                  {section.sectionTitle}
                </Heading>
                <RichText listType="default">{section.richBody}</RichText>
              </div>
            )
          })}
        </Col>
      </Row>
    </Container>
  )
}

LegalModule.propTypes = {
  pageTitle: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.object),
}

export { LegalModule }
export default LegalModule
