import PropTypes from 'prop-types'
import * as styles from './TableOfContents.module.scss'
import { Eyebrow } from '../../../typography/Eyebrow'
import { Link } from '../../../typography/Link'

const TableOfContents = ({ pageTitle, anchorLinks }) => {
  return (
    <div
      role="navigation"
      aria-label="Table of Contents same page links"
      className={styles.TableOfContents}
      data-qa="legal-module-toc"
    >
      {anchorLinks.map(anchor => {
        return (
          <p key={`link-${anchor.to}`} className={styles.LinkText}>
            <Link to={anchor.to} data-qa="legal-module-toc-link">
              {anchor.name}
            </Link>
          </p>
        )
      })}
    </div>
  )
}

TableOfContents.propTypes = {
  pageTitle: PropTypes.string,
  anchorLinks: PropTypes.arrayOf(PropTypes.object),
}

export { TableOfContents }
export default TableOfContents
