import PropTypes from 'prop-types'
import { Layout } from '../components/layouts/layout'
import { PageMeta } from '../components/page-meta'
import { LegalModule } from '../components/modules/LegalModule'

const LegalPageTemplate = props => {
  const { location, pageContext } = props
  const { navMenus, pageData } = pageContext
  const { pageTitle, sections } = pageData

  return (
    <Layout
      location={location}
      pageContext={pageContext}
      menu={navMenus}
      showSubnav
      showSubnavOnScroll={false}
    >
      <PageMeta
        language={pageData.language}
        title={pageData.title}
        keywords={pageData.seoKeywords}
        description={pageData.seoDescription}
        image={pageData.ogImage}
        pathname={pageData.urlPath}
        robots={pageData.robotsMetaTag}
        canonicalUrlOverride={pageData.canonicalUrlOverride}
      />

      <LegalModule pageTitle={pageTitle} sections={sections} />
    </Layout>
  )
}

LegalPageTemplate.propTypes = {
  location: PropTypes.object,
  pageContext: PropTypes.object,
}

export default LegalPageTemplate
